














































































































































import {Vue, Component} from 'vue-property-decorator'
import Confirmacion from '@/components/Confirmacion.vue'
import {State, Action, Mutation} from 'vuex-class'
import { IParteCabeceraAprobacion } from '@/store/Parte/types';
import { secondAxios } from '@/plugins/axios';
import ResponseCustom from '@/entidades/ResponseCustom';
import { ParteApiModel } from '@/entidades/Plantacion/Partes/ParteApiModel';
import TablaReutilizable from '@/components/TablaReutilizable.vue'
import { TableBtnModel } from '@/entidades/TableBtnModel';
import { mensajes } from '@/plugins/notify';
@Component({
    components:{
        Confirmacion,
        TablaReutilizable
    }
})
export default class extends Vue
{   
    @State('partesEnviados',{namespace:'parteModule'}) partes!: ParteApiModel[];
    @Action('cargarPartesEnviados', {namespace:'parteModule'}) cargarPartesEnviados!:Function;
    @Action('anularParte', {namespace:'parteModule'}) anularParte!:Function;
    Anulando=false;
    indiceAnulacion=-2;
    DetallandoMotivo=false;
    headers:any[]= 
    [
        {
          text: 'Hacienda',
          align: 'start',
          sortable: false,
          value: 'haciendaName',
          class:'text-white',
          width:250 
        },
        {
          text: 'Fecha',
          align: 'start',
          sortable: false,
          value:'fecha',
          width:250 
        },
        { 
            text: 'Acciones', 
            align:'center', 
            value: 'acciones', 
            sortable: false,
            width:150 
        },
        
        
    ];
    cabeceraDetalles:any[]= 
    [
        {
          text: 'Labor',
          align: 'start',
          sortable: false,
          value: 'laborName'
        },
        {
          text: 'Lote',
          align: 'start',
          sortable: false,
          value:'loteName'
        },
        {
          text: 'Cuadrilla',
          align: 'start',
          sortable: false,
          value:'cuadrillaId'
        },
        {
          text: 'Cantidad',
          align: 'end',
          sortable: false,
          value:'cantidad'
        },
        {
          text: 'Plantulas',
          align: 'end',
          sortable: false,
          value:'cantidadPlantula'
        },
        {
          text: 'Precio',
          align: 'end',
          sortable: false,
          value:'precio'
        },        
    ];
    filtroActual={
        id:1,
        nombre:'Todos',
        aprobado:true,
        rechazado:true
    }
    filtros=[
        {   
            id:1,
            nombre:'Todos',
            aprobado:true,
            rechazado:true
        },
        {   
            id:2,
            nombre:'Aprobados',
            aprobado:true,
            rechazado:false
            
        },
        {   
            id:3,
            nombre:'Rechazados',
            aprobado:false,
            rechazado:true
        },
        {   
            id:4,
            nombre:'Pendientes',
            aprobado:false,
            rechazado:false
        }
    ]
    flujoTmp=[
        {
        nivel: 0,
        usuario: "Ejemplo",
        aprobado: false,
        rechazado: false,
        motivo: "Motivo ejemplo",
        estado: "Pendiente de Aprobacion",
        fecha: "0001-01-01"
        },
    ]
    rules:any=[
        //@ts-ignore
        value => !!value || 'Valor obligatorio.',
        //@ts-ignore
        value => (value && value.length >= 3) || 'Debe contener al menos 5 caracters',
    ]
    botones:TableBtnModel[]=[
        {
            icon:'fa-search',
            label:'',
            action:'abrirVerDetalles',
            tooltip:'Ver detalle',
            necesitaIndex:false
        },
        {
            icon:'fa-question-circle',
            label:'',
            action:'abrirVerEstado',
            tooltip:'Consultar flujo de aprobación',
            necesitaIndex:false
        },
        {
            icon:'fa-ban',
            label:'',
            action:'abrirAnularParte',
            tooltip:'Anular parte',
            necesitaIndex:true
        },
    ];
    mostrandoDetalles=false;
    mostrandoEstado=false;
    parteTmp?:IParteCabeceraAprobacion;
    motivo=''
    get partesFiltrados(){
        
        if(this.filtroActual.id==1){
            return this.partes;
        }else{
            return this.partes.filter(e=>e.aprobada==this.filtroActual.aprobado && e.rechazada==this.filtroActual.rechazado);
        }
    }

    async abrirVerEstado(parte: IParteCabeceraAprobacion){
        this.cargarEstadoParte(parte.parteId);
    }
    async cargarEstadoParte(parteId: number){
        mensajes.advertencia('Consultando flujo de aprobación...')
        await secondAxios({
            url:`Parte/ObtenerFlujoAprobacion/${parteId}`,
            method:'get',
        }).then((data)=>{
            var resp:ResponseCustom<any>=data.data;
            console.log("Respuesta; ", resp)
            if(resp.isSuccess){
                this.flujoTmp=resp.isResult;
                this.mostrandoEstado=true;
            }else{
                mensajes.error('Error: '+ resp.isMessage);
                //console.log('Error: ', resp.isMessage);
            }
        }).catch((err)=>{
            mensajes.errorDeConexion();
        })
    }
    async abrirVerDetalles(parte: IParteCabeceraAprobacion){
        this.parteTmp=parte;
        this.mostrandoDetalles=true;
    }

    abrirAnularParte(dato: any){
        var parte:IParteCabeceraAprobacion=dato.item
        this.parteTmp=parte;
        this.indiceAnulacion=dato.index;
        console.log('Anulando ', dato)
        this.Anulando=true;
    }
    async confirmarAnularParte(){
        if(this.motivo.length>=5){
            mensajes.advertencia('Solicitando anulación, por favor espere...')
            var resp=await this.anularParte({
                dato:{
                    parteId: this.parteTmp?.parteId,
                    usuarioId: this.$store.state.authModule.usuarioId,
                    motivo:this.motivo
                },
                index:this.indiceAnulacion
            });
            if(resp==true){
                this.cargarPartesEnviados(this.$store.state.authModule.usuarioId);
                this.DetallandoMotivo=false;
            }
        }else{
            mensajes.error('Motivo inválido');
        }
    }
    
    async created() {
        /*
        //@ts-ignore
        //var local=JSON.parse(localStorage.getItem('vuex'));
        //@ts-ignore
        //console.log("El vuex es ",local.authModule)
        */
       await this.cargarPartesEnviados(this.$store.state.authModule.usuarioId).then(()=>{
            
        }).catch((err:string)=>{
            console.log('Error')
        })
        
    }
}
