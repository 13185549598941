





































import {Vue, Component, Prop} from 'vue-property-decorator'

import Confirmacion from '@/components/Confirmacion.vue'
import {TableBtnModel} from '@/entidades/TableBtnModel'
import {HeaderModel} from '@/entidades/HeaderModel'
@Component({
    components:{
        Confirmacion,
    }
})

export default class extends Vue
{   
    @Prop({type:Boolean ,required:false, default:false}) llevaTitulo!:Boolean;
    @Prop({type:String ,required:false, default:''}) titulo!:String;
    
    @Prop({type:Array ,required:true, default:false}) headers!:Array<HeaderModel>;
    @Prop({type:Array ,required:true, default:[]}) items!:[];

    @Prop({type:Boolean ,required:false, default:false}) llevaBotones!:Boolean;
    @Prop({type:Array ,required:false, default:[]}) botones!:Array<TableBtnModel>;

}
