




















import {  Vue ,Component, Prop } from 'vue-property-decorator'

@Component
export default class extends Vue
{
    @Prop({type:String ,required:true})
    mensaje!:string

    @Prop({type:Boolean ,required:true})
    modal!:boolean

}
